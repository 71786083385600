.dashboard-content-wrapper {
  margin-top: 45px;
  height: 100vh;

  .dash-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
  }

  .dashboard-small-card {
    // flex:1;
    width: 23%;
    min-width: 150px;
    border-radius: 11.958px;
    border: 1.196px solid #0075ff;
    background: #f4f9ff;
    padding: 1.5rem;
    position: relative;

    h6 {
      margin-bottom: 0;
      // color: #a5a2b8;
      font-family: "DM Sans", sans-serif;
      font-size: 0.99rem;
      // border: 1px solid #EFEFF0;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    h5 {
      margin-bottom: 0;
      color: #2a2455;
      font-family: "DM Sans", sans-serif;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  .main-card-wrap {
    .insights-card {
      // width: 424px;
      height: 528px;
      border-radius: 8px;
      // background: rgba(53, 46, 91, 1);

      p {
        font-weight: bold;
        font-size: 1.5rem;
        margin: 1.5rem;
      }

      ul {
        padding-left: 3rem;
        li {
          // color: #fff;
          font-family: "DM Sans", sans-serif;
          width: 75%;
          margin-bottom: 1rem;
          padding-left: 0.75rem;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 174.2%;
        }
      }

      #arrow-list::-webkit-scrollbar {
        width: 6px; /* Set the width of the scrollbar */
      }

      #arrow-list::-webkit-scrollbar-thumb {
        background-color: #6863f3;
        border-radius: 6px;
      }

      #arrow-list::-webkit-scrollbar-track {
        background-color: #f1f1f1; /* Set the color of the track */
      }
    }
  }

  .chat-card {
    .card-header {
      padding: 16px 24px;
    }

    p {
      font-weight: bold;
      font-size: 1.5rem;
      margin: 1.5rem;
    }

    .card-body {
      height: 450px;
      overflow-x: auto;
    }
    ::-webkit-scrollbar {
      width: 6px; /* Set the width of the scrollbar */
      height: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #6863f3;
      border-radius: 6px;
    }

    ::-webkit-scrollbar-track {
      background-color: #f1f1f1; /* Set the color of the track */
    }

    margin-bottom: 2rem;
  }
}

.para_text {
  text-align: left;

}

.list-box {
  border: 1px solid #d2d2d2;
  box-shadow: 5px 5px 5px 2px rgba(204, 204, 204, 0.379);
  // box-shadow: 3px 3px 3px rgba(180, 179, 179, 0.444) inset;
}

#arrow-list {
  margin-left: 1rem;
  margin-top: 1rem;
  li {
    list-style-image: url("/assets/images/list-arrow.png");
  }
}

.blurred-value {
  filter: blur(5px); /* Blurs only the value */
}

.lock-overlay {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0.5rem;
}

.lock-overlay img {
  width: 24px;
  height: 24px;
}

.blurred-containerr {
  position: relative;
}

.blurred-containerr.blurred #arrow-list {
  filter: blur(5px); 
}

.lock-overlayy {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10; 
}

.nodata{
  font-size: 1.3rem;
  font-weight: 500;
  margin: 20% 0%;
}


