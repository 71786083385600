/* General Styles */
.text {
  text-align: center;
  color: rgba(110, 110, 110, 1);
  font-family: 'Poppins', sans-serif;
}

.head {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
}

.text2 {
  text-align: center;
  color: rgba(36, 36, 36, 1);
  font-family: 'Poppins', sans-serif;
}

/* Gradient Text */
#gradient-text {
  background: linear-gradient(62.57deg, #FBB040 5.17%, #FBB040 12.63%, #FFF200 59.88%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

/* Alignment for icons and images */
.alignimg {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.iconimg {
  width: 12%;
}

#pcimg {
  width: 100%;
}

.services_ {
  min-height: 100vh; /* Ensure full-screen height on mobile */
  background-image: url('bg1.png');
  background-size: cover;
}

/* Transition effect on hover */
.transitionnnnnn {
  text-align: end;
  font-size: 18px;
  transition: transform 0.3s;
  cursor: pointer;
}

.transitionnnnnn:hover {
  transform: scale(1.05);
}

/* Responsive image handling for mobile */
.iconimg2-res, .iconimg1-res, .iconimg3-res, .iconimg4-res {
  display: none;
}

.img-res {
  display: none;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .transitionnnnnn {
      text-align: center;
      font-size: 16px;
  }

  .head {
      text-align: center;
  }

  #iconimg2-res, #iconimg1-res, #iconimg3-res, #iconimg4-res {
      display: block;
      width: 20%;
      margin: 0 auto;
  }

  .img-res {
      display: flex;
      justify-content: center;
  }

  #alignimg, #alignimg2 {
      display: none;
  }

  #alignimg-pc {
      display: block;
      margin-top: 20px;
  }

  .iconimg {
      width: 20%; /* Larger icons for mobile screens */
  }

  #pcimg {
      width: 100%; /* Full width for the PC image */
  }

  /* Font adjustments for mobile */
  .text2 {
      font-size: 1.5rem;
  }

  .transitionnnnnn {
      font-size: 16px;
  }

  #gradient-text {
      font-size: 1.8rem;
  }

  .services_ {
      padding: 20px; /* Add padding for mobile */
  }

  #pcimg {
      width: 90%; /* Slight reduction to fit nicely on smaller screens */
  }
}

/* Smaller mobile devices */
@media (max-width: 576px) {
  .iconimg {
      width: 30%; /* Even larger icons for smaller screens */
  }

  #pcimg {
      width: 100%; /* Full width */
  }

  .text2 {
      font-size: 1.2rem;
  }

  .transitionnnnnn {
      font-size: 14px;
  }

  #gradient-text {
      font-size: 1.5rem;
  }

  /* Adjust for very small screens */
  .img-res {
      justify-content: center;
  }
}
