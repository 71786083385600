.bbbbbbbbbbbbbbbb {
  background-color: rgba(27, 30, 49, 1) !important;
  color: #513b3b !important;
  border: none;
  padding: 10px 20px;
  transition: background-color 0.3s, color 0.3s;
}

.bbbbbbbbbbbbbbbb:hover {
  color: black;
  background: linear-gradient(
    62.57deg,
    #fbb040 5.17%,
    #fbb040 12.63%,
    #fff200 59.88%
  );
}

.nnv{
  margin-right: 1rem;
  margin-left: auto;
}

.divider0 {
  width: 1px;
  height: 23px;
  background: rgba(232, 232, 232, 1);
  margin-right: 40px;
  margin-top: 7px;
}

.nav-linking {
  margin-right: 15px;
  font-size: 17px !important;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: black;
}

.navbar-toggler:hover {
  background: linear-gradient(
    62.57deg,
    #fbb040 5.17%,
    #fbb040 12.63%,
    #fff200 59.88%
  );
  color: black;
  border: none !important;
}

#navv {
  background-color: #ffffff !important;
  padding: 15px 0px;
}

#navbar_buttonsss{
  padding-top: 3px;
}

.nav-btn {
  border: none;
  background-color: #ffffff;
}

#navbar_buttonsss {
  color: rgb(94, 97, 99);
}

#navbar_buttonsss:hover {
  color: rgb(0, 0, 0);
}

/* Ensures the background is white and the text is black when the navbar is collapsed on mobile */
@media (max-width: 768px) {
  .navbar-collapse {
    background-color: #ffffff !important; /* White background for collapsed menu */
    text-align: center; /* Center align text */
  }

  .nnv{
    margin-right: 0px;
    margin-left: 0px;
    padding-right: 0px;
  }

  .nav-item{
    display: flex;
    justify-content: center;
  }

  .nav-linking {
    color: black !important; /* Black text color for links */
    margin: 10px 0; /* Some margin between links for better readability */
  }

  .loginDiv {
    margin-top: 10px;
  }

  .navbar-toggler {
    margin-right: 0; /* Adjust toggler button alignment */
  }

  .divider0{
    display: none;
  }

  /* Optional: Add some padding to make the links easier to click */
  .navbar-nav {
    padding: 10px;
  }
}

#nav-btn-f {
  outline: none;
}

#nav-btn-f:focus {
  outline: none;
  box-shadow: none; /* This will remove the default focus shadow as well */
}