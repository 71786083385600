.tools {
    padding: 0 30px;
    width: 100%;
  }
  .tools ul {
    list-style-type: none;
    padding: 0;
  }
  .toolsHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width:850px;
  }
  .tools .toolsHeaderList {
    display: flex;
    align-items: center;
    width: 100%;
    /* justify-content: space-between; */
    height: 60px;
    border-radius: 8px;
}
.tools .toolsHeaderList li {
    background: #edeff1;
    margin:0 0.75%;
    height: 46px;
    line-height: 46px;
    text-align: center;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: rgb(157, 162, 166);
    border-radius: 8px;
    min-width: 185px;
  }
  .tools .toolsHeaderList li .active {
    background: #0075ff;
    border-radius: 8px;
    color: white;
    min-width: 185px;
    width: -moz-fit-content; 
     width: fit-content;
  }


.future-simulator-header{
  display:flex;
  justify-content:space-between;
  align-items:center;
  margin-bottom:20px;
  border:#EFEFF0 2px solid;
  padding:5px;
  border-radius: 8px;
}

.fs-date-selector{
  width: 60%;
  display:flex;
  justify-content:space-between;
  align-items:center;
}

.graph-container{
  border:#EFEFF0 2px solid;
  padding:20px;
  border-radius: 8px;
  width:980px;
  h2{
    font-family: "DM Sans", sans-serif;
    font-size: 25px;
    line-height: 32.55px;
    font-weight: 700;
    margin-bottom:20px;
  }
}

.buttons-section{
  min-width:250px;
  display:flex;
  justify-content:space-between;
  align-items:center;
  margin-bottom:20px;

}

.sav_res{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  margin-bottom: 15px;
}

.reset-button{
  width: 100%;
  margin-right: 10px;
}

.sessions-container {
  width: 100%;
  padding: 10px;
  border: 1.5px solid #c8c8c8;
  border-radius: 10px;
}

.main-row {
  display: flex;
}

.left-col {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.customSessionRow {
  margin: 10px;
}

.customDateInput,
.date_inp,
.select-width {
  width: 100%;
  border: 1px solid #ccc; /* Optional border */
  background-color: white;
  border-radius: 5px;
  padding: 8px; /* Optional padding */
  box-sizing: border-box; /* Ensure padding doesn't affect width */
  text-align: left;
}

.date_inp {
  display: block;
  width: 100%; 
}

.right-col {
  width: 70%;
  padding: 10px;
}

.right-col textarea {
  width: 100%;
  height: 100%;
}

.react-datepicker-wrapper{
  display: inline;
  text-align: left;
}


.customDateInput2{
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 7px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  text-align: left;
}

.customDateInput3{
  margin: 10px;
}


.ree{
  width: 100%;
  margin: 10px;
}

.ree2{
  width: 100%;
}
