/* Image styling */
.abt-img {
    width: 100%;
    height: auto; /* Ensure image scales properly */
}

/* About Us section */
.About {
    font-weight: 500;
    font-size: 2.5rem; /* Default font size */
    margin-bottom: 0.5rem;
}

#aboutUs {
    font-size: 2.5rem; /* Keep the font size consistent */
    background-image: linear-gradient(90deg, #FBB040 0.61%, #FFF200 42%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* Text content styling */
.aboutletters {
    font-family: Poppins, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.6rem;
    color: #333;
    margin-bottom: 1.5rem;
}

.aboutpart2 {
    font-family: Poppins, sans-serif;
    font-weight: 600;
    font-size: 1.2rem;
    color: #333;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
    .About {
        font-size: 2rem;
    }
    #aboutUs {
        font-size: 2rem;
    }
    .aboutletters, .aboutpart2 {
        font-size: 1rem;
    }
}

@media (max-width: 768px) {
    .About {
        font-size: 1.8rem;
        text-align: center; /* Center-align for smaller screens */
    }
    #aboutUs {
        font-size: 1.8rem;
    }
    .aboutletters, .aboutpart2 {
        font-size: 0.95rem;
        text-align: justify; /* Make text more readable on mobile */
    }
    .ps-md-5 {
        padding-left: 1rem; /* Reduce padding on smaller screens */
    }
}

@media (max-width: 576px) {
    .About {
        font-size: 1.6rem;
        text-align: center;
    }
    #aboutUs {
        font-size: 1.6rem;
    }
    .aboutletters, .aboutpart2 {
        font-size: 0.9rem;
    }
    .abt-img {
        margin-bottom: 2rem; /* Add spacing below the image on mobile */
    }
}
