
.popUUpBg {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(82, 82, 82, 0.5);
    z-index: 999;
}

.popUUpBg .filterPopUUpHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.popUUpBg .filterPopUUpHeader p {
    font-size: 25px;
    margin-bottom: 0;
    color: rgb(0, 0, 0);
    font-weight: 600;
}

.popUUpBg .filterPopUUpHeader .closeFilter {
    height: 45px;
    width: 45px;
    line-height: 42px;
    text-align: center;
    background: rgb(237, 239, 241);
    border-radius: 50%;
}

.filterPopUUp {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    max-width: 600px;
    width: 80%;
    padding: 20px;
    position: relative;
}

.filterPopUUpBody {
    pointer-events: auto;
}

.filterPopUUpHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.popUUpTitle {
    margin: 0;
}

.closeFilter {
    cursor: pointer;
}

.questionContainer {
    height: 400px;
    overflow-y: auto;
    margin: 20px 0;
    text-align: left;
    padding: 2rem;

    /* Custom scrollbar styles for the specific div */
    scrollbar-width: thin;
    scrollbar-color: #6863f3 #f1f1f1;
    /* thumb color track color */

    /* Webkit (Chrome, Safari) specific styles */
    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #6863f3;
        border-radius: 6px;
    }

    &::-webkit-scrollbar-track {
        background-color: #f1f1f1;
    }
}

.submitButton {
    background-color: #9574ff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.closeIcon {
    width: 20px;
    height: 20px;
}

.popUUpBg::before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -0.25em;
}

.filterPopUUp {
    display: inline-block;
    vertical-align: middle;
}

.question {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
}

.inputField {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 15px;
}

/* Custom scrollbar styles */
/* ::-webkit-scrollbar {
    width: 6px;
    height: 5px;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #6863f3;
    border-radius: 6px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  } */

.heading-mantra {
    color: var(--Lite-Font, #1B1B1B);
    font-family: DM Sans;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 0.5rem 1rem;
}

.mantra-description {
    width: 1100px;
    height: 180px;
    border-radius: 20px;
    background: linear-gradient(180deg, rgba(0, 117, 255, 0.10) 0%, rgba(255, 255, 255, 0.00) 100%);
    padding: 40px 40px 70px 40px;
}

.mantra-text {
    color: #000;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 35px;
    /* 194.444% */
}

.inverted-comma {
    margin-left: auto;
}

.mantra-box {
    width: 95%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    font-family: 'Inter';
}

.add-btn {
    width: 10rem;
    padding: 1rem 2rem;
    display: flex;
    margin-left: auto;
    border-radius: 10px;
    color: white;
    background: #0075FF;
    white-space: nowrap;
    cursor: pointer;
}

.more {
    position: relative;
    z-index: 1;
}

.questionnaire-card {
    background-color: #fff6fb;
    border-radius: 15px;
    padding: 2rem 1.8rem;
    /* width: 30%; */
    margin: 1rem;

    h4 {
        font-size: 1rem;
        line-height: 1.8rem;
    }

    p {
        color: blue;
        text-decoration: underline;
        cursor: pointer;
    }
}

.more-vert {
    position: absolute;
    right: 1.5rem;
    display: flex;
    padding: 5px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.more-menu {
    background-color: white;
    position: absolute;
    z-index: 2;
    right: 1rem;
    display: flex;
    flex-direction: column;

    p {
        &:nth-child(1) {
            border-bottom: 1px solid grey;
        }

        display: flex;
        width: 100%;
        text-decoration: none;
        color: grey;
        padding: 0.5rem 1rem;
        font-size: 0.9rem;
        cursor: pointer;
        margin-bottom: 0;
        transition: 0.2s all ease;

        &:hover {
            background-color: #7a7a7c;
            color: white;
        }
    }
}

.questions{
    font-size: 13px;
    font-weight: 500;
}

.answers{
    font-size: 12px;
    font-weight: 500;
    color:blue;
    /* margin-left: 10px; */
}