/* styles.css */

.popUUpBg {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(82, 82, 82, 0.5);
    z-index: 999;
}

.answer {
    border: rgb(192, 191, 191) 1.5px solid;
    border-radius: 5px;
    padding: 5px 10px;
    width: 95%;
}

.popUUpBg .filterPopUUpHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.popUUpBg .filterPopUUpHeader p {
    font-size: 25px;
    margin-bottom: 0;
    color: rgb(0, 0, 0);
    font-weight: 600;
}

.popUUpBg .filterPopUUpHeader .closeFilter {
    height: 45px;
    width: 45px;
    line-height: 42px;
    text-align: center;
    background: rgb(237, 239, 241);
    border-radius: 50%;
}

.filterPopUUp {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    max-width: 600px;
    width: 80%;
    padding: 20px;
    position: relative;
}

.filterPopUUpBody {
    pointer-events: auto;
}

.filterPopUUpHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.popUUpTitle {
    margin: 0;
}

.closeFilter {
    cursor: pointer;
}

.questionContainer {
    height: 400px;
    overflow-y: auto;
    margin: 20px 0;
    text-align: left;
    padding: 2rem;

    /* Custom scrollbar styles for the specific div */
    scrollbar-width: thin;
    scrollbar-color: #6863f3 #f1f1f1;
    /* thumb color track color */

    /* Webkit (Chrome, Safari) specific styles */
    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #6863f3;
        border-radius: 6px;
    }

    &::-webkit-scrollbar-track {
        background-color: #f1f1f1;
    }
}

.submitButton {
    background-color: #9574ff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.closeIcon {
    width: 20px;
    height: 20px;
}

.popUUpBg::before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -0.25em;
}

.filterPopUUp {
    display: inline-block;
    vertical-align: middle;
}

.question {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
}


.ipf {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 15px;
}

/* Custom scrollbar styles */
/* ::-webkit-scrollbar {
    width: 6px;
    height: 5px;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #6863f3;
    border-radius: 6px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  } */