.container4 {
  height: auto;
  min-height: 100vh;
  background: rgba(27, 30, 49, 1);
  padding: 20px;
}

.center-text {
  justify-content: center;
  text-align: center;
  color: rgba(234, 234, 234, 1);
  padding-top: 30px; /* Reduced padding for mobile */
}

.journal {
  padding-top: 10px; /* Reduced top padding */
  color: rgba(255, 255, 255, 1);
  text-align: center;
  font-family: "Poppins", sans-serif;
  justify-content: center;
  width: 100%;
  font-size: 24px; /* Reduced font size for mobile */
}

.review {
  background: rgba(36, 40, 60, 1);
  padding: 15px;
  border-radius: 5px;
  min-height: 320px;
  height: auto;
  width: 100%; /* Full width for better responsiveness */
  max-width: 100%; /* Remove max-width on mobile */
  border: 1px solid rgba(53, 57, 79, 1);
  margin-bottom: 20px; /* Add margin for spacing between reviews */
}

.img-svg {
  display: flex;
  justify-content: center;
  gap: 8px;
}

.img-svg img {
  max-width: 35px; /* Reduced size for stars on mobile */
  height: auto;
}

.inneralign {
  padding: 15px; /* Reduced padding */
  text-align: center;
  border-top: 1px solid rgba(53, 57, 79, 1);
  border-bottom: 1px solid rgba(53, 57, 79, 1);
}

.testimonial-name {
  font-size: 16px; /* Smaller font for mobile */
  background: linear-gradient(62.57deg, #fbb040 5.17%, #fbb040 12.63%, #fff200 59.88%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.position {
  color: rgba(255, 255, 255, 1);
  font-size: 12px; /* Smaller font for mobile */
}

.review-text {
  color: rgba(235, 236, 243, 1);
  font-size: 13px; /* Slightly reduced font size */
  padding-top: 15px; /* Adjusted padding */
  font-family: "Poppins", sans-serif;
}

.divider4 {
  color: rgba(50, 54, 79, 1);
  margin-top: 20px;
}

.navigation-icons {
  text-align: center;
  margin-top: 20px;
}

#lefticon, #righticon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

#lefticon img, #righticon img {
  max-width: 25px; /* Smaller icon size for mobile */
  height: auto;
  cursor: pointer;
}

.navigation-icons .disabled {
  pointer-events: none;
  opacity: 0.5;
}

/* Media Queries for responsive design */
@media only screen and (min-width: 768px) {
  .review {
    margin-top: 72px;
  }

  .journal {
    font-size: 28px; /* Larger font for tablets */
  }

  .img-svg {
    justify-content: flex-start;
  }

  #lefticon, #righticon {
    display: block;
    padding-top: 25px;
  }
}

@media only screen and (min-width: 1024px) {
  .journal {
    font-size: 32px; /* Larger font for desktop */
  }

  .review {
    max-width: 400px; /* Reintroduce max-width for larger screens */
  }

  #lefticon img, #righticon img {
    max-width: 35px; /* Larger icon size for desktop */
  }
}
