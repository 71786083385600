.col-4{
  text-align: left;
  p{
    font-size: 25px;
  }
}

.roww{
  margin: 0px 20px 0px 60px;
  display: flex;
}

.rowwb{
  margin: 0px 20px 0px 80px;
  display: flex;
}

#msg{
  font-size: 12px;
  margin-left: 90px;
}

.form-rows{
  text-align: left;
  width: 300px;
  margin-right: 20px;
  margin-left: 30px;

  label{
    font-size: 16px;
    font-weight: 600;
  }
}

.inpp{
  border: 0.5px solid rgb(219, 219, 219);
  border-radius: 5px;
  background-color: white;
  padding: 7px;

  &:hover{
    border: 1px solid #007bff;
    transition: 1s ease;
  }
}

.inpp2{
  border: none;
  background-color: white;
  padding: 7px;
  margin-bottom: 0;

  &:focus{
    outline: none;
  }
}

.cancel_button{
  margin: 10px;
  width: 70px;
  border-radius: 5px;
  color: white;
  background-color: rgb(205, 205, 205);
  padding: 5px;

  &:hover{
    transition: all 0.5s ease;
    color: black;
  }
}

.save_button{
  margin: 10px;
  width: 70px;
  border-radius: 5px;
  color: white;
  background-color:  #007bff;
  padding: 5px;
}

.delete_button{
  margin: 10px;
  width: 160px;
  border-radius: 5px;
  color: white;
  background-color: red;
  padding: 5px;
}

.confirm_button{
  margin: 10px;
  width: 100px;
  border-radius: 5px;
  color: white;
  background-color: red;
  padding: 5px;
}

.error-message{
  color: red;
  font-size: 14px;
  font-style: italic;
}

.pw{
  height: 34px;
  border-radius: 5px;
  display: flex;
  border: 0.5px solid rgb(219, 219, 219);

  &:hover{
    border: 1px solid #007bff;
    transition: 1s ease;
  }
}

.eye_icon{
  text-align: center;
  align-items: center;
  align-content: center;
  margin-right: 10px;
  margin-bottom: 5px;
}

/* Overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's on top of everything */
}

/* Popup Box */
.popupp {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.inppp{
  border: 0.5px solid rgb(219, 219, 219);
  border-radius: 5px;
  background-color: white;
  padding: 5px;
}





