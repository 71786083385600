.additionfeature_ {
  background: rgba(244, 244, 244, 1);
  background-image: url("bg2.png");
  background-position: 0%;
  background-size: cover; /* Ensure background covers the area */
}

.img1 {
  width: 100%; /* Ensure image scales with screen width */
  height: 475px; /* Maintain aspect ratio */
  max-width: 475px; /* Limit maximum width */
  border-radius: 10px;
}

.heading {
  background: url("icon (5).svg");
  background-repeat: no-repeat;
  background-position-x: 15px;
  padding-left: 35%; /* Adjust for better alignment with text */
  padding-top: 3.2%;
  font-size: 35px;
  font-family: Poppins, sans-serif;
  height: 90px;
  margin-bottom: 25px;
  text-align: left;
  color: #000; /* Default text color */
  line-height: 1.2; /* Adjust line-height for better spacing */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* Add text shadow */
}

#colortext {
  background: linear-gradient(180deg, #fbb040 0%, #fff200 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: 600;
  line-height: 1.2;
}

#para1 {
  width: 90%;
  font-size: 20px;
  text-align: left;
  color: #333; /* Default text color */
}

#boxhead {
  text-align: left;
}

@media(max-width:768px) {
  .heading {
    background-position-x: 4px; /* Align background for smaller screens */
    background-position-y: 5px;
    padding-left: 4%; /* Reduce padding for smaller screens */
    padding-top: 2%;
    font-size: 28px; /* Reduce font size for smaller screens */
    background-size: 60%; /* Adjust background size for smaller screens */
    margin-bottom: 0px;
  }

  .img1 {
    width: 400px;
    height: 400px; /* Maintain aspect ratio */
  }

  #boxhead {
    font-size: 24px; /* Adjust font size for smaller screens */
  }

  #mobdiv{
    padding: 0px 30px;
  }

  #para1 {
    font-size: 18px; /* Adjust font size for smaller screens */
    width: 100%; /* Ensure full width */
  }
}
