.header-wrapper {
  // margin-bottom: 67px;
  display: flex;
  justify-content: space-between;
  // align-items: center;
  border-bottom: 1px solid rgb(188, 188, 188);
  padding-bottom: 13px;
  margin-bottom: 30px;
  .header-left-wrap {
    display: flex;

    .new-trade-btn {
      border-radius: 8.444px;
      background: #0075ff;
      width: 216.379px;
      display: flex;
      align-items: center;
      color: #fff;
      padding: 14px 51px;
      box-shadow: none;
      border: none;
      margin-right: 31px;
      
      max-height: 49.609px;
      .plus-icon {
        margin-left: 15px;
      }
    }
    .react-datepicker-wrapper {
      max-height: 49.609px;
      width: 17%;
    }

    .outline-button-cal,
    .outline-button-man {
      margin-top: 4px;
      border-radius: 8.444px;
      width: 152.477px;
      height: 44.512px;
      box-shadow: none;
      border: none;
      border: 1px solid #0075ff;
      color: #2a2455;
      font-family: "DM Sans", sans-serif;
      font-size: 15.153px;
      font-weight: 500;
      line-height: normal;
      display: flex;
      align-items: center;
      justify-content: center;
      & img {
        margin-left: 5px;
      }
      &:hover {
        background-color: #0075ff;
        color: #fff;
        & img {
          margin-left: 5px;
          filter: invert(1);
        }
      }
    }

    .outline-button-cal {
      margin-left: 83px;
      margin-right: 13px;
    }
  }

  .profile-wrapper {
    display: flex;
    align-items: baseline;

    .profile-dropdown {
      background-color: transparent;
      border: none;

      &::after {
        content: url(../../assets/images/chevron-down.svg);
        border: none;
        margin-left: 28px;
      }
    }

    .profile-name {
      color: #11263c;
      text-align: right;
      font-family: "Inter", sans-serif;
      font-size: 18.34px;
      font-style: normal;
      font-weight: 700;
      line-height: 29.803px;
    }
  }
}

.DateInput {
  border-radius: 8.444px;
  background: #f8f7ff;
  width: 190px;
  height: 49.609px;
  box-shadow: none;
  border: none;
  margin-right: 31px;
  color: #8b899b;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 0;
  padding-left: 24px;

  .clock-icon {
    width: 48.553px;
    background-color: #0075ff;
    margin-left: auto;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 8.444px;
    border-bottom-right-radius: 8.444px;
  }
}

.cross-icon {
  display: flex;
  height: 49px;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  cursor: pointer;
}

.header-title {
  padding-left: 2rem;
  font-weight: bold;
  font-size: 2rem;
}

#dropdown-basic{
  margin-top: 5px;
}

.linkc{
  background-color: black;
}

.drop_button{
  align-content: center;
  text-align: center;

  img{
    cursor: pointer;
    border-radius: 5px;
  }
}

.header-right-wrap{
  display: flex;
  margin-right: 15px;
}

.sub-menu{
  top: 12%;
  position: absolute;
  border-radius: 5px;
  right: 3%;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(102, 102, 102, 0.2), 0 6px 20px 0 rgba(112, 112, 112, 0.19);
  width: 190px;
  z-index: 1000;
}

.sub-menu-opts-1{
  display: flex;
  margin: 10px;
  justify-content: flex-start;
  text-align: center;

  img{
    border-radius: 30px;
  }
}

.opt-1-head{
  width: 70px;
  text-align: left;
  margin-left: 20px;
}

.sub-menu-opts{
  margin: 10px 15px;
  text-align: left;
  cursor: pointer;
}
