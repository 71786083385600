.About {
    font-weight: 500;
    font-size: 40px;
}

.ap{
    text-decoration: none !important;
    cursor: pointer;
    color: inherit !important;

    &:hover{
        color: inherit;
        text-decoration: none !important;
    }

    &:visited{
        color: inherit;
        text-decoration: none !important;
    }
}

#Us {
    font-size: 40px;
    background-image: linear-gradient(90deg, #FBB040 0.61%, #FFF200 42%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.btn-div{
    display: flex;
    justify-content: center;
}
.message1,.exp{
    color: #808080 !important;
}

.random {
    width: 100%;
    text-align: center;
}

.buttonn{
    background-color: #FBB040;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
}

.forr {
    margin: 0px;
    width: 75%;
}

#connn{
    align-items: center;
    padding: 0px;
}

@media(max-width:768px){
    .bet-two{
        margin-top: 10% !important;
    }
}