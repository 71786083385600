.popup {
  position: fixed;
  opacity: 1;
  padding: 0px;
  top: 110px;
  right: 30px;
  color: black;
  z-index: 2000;
}

.popup-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.popup-close {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.popup-matter {
  width: 85%;
}

.popup2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.popup-content-2 {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  width: 100%;
  text-align: center;
}

.popup-matter-2 h2 {
  margin-bottom: 20px;
  font-size: 18px;
  color: #333;
}

.popup-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.popup-button:hover {
  background-color: #0056b3;
}
