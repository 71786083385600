.strategy-box {
  width: 95%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  font-family: 'Inter';
}
.more {
  position: relative;
  z-index: 1;
}

.strategy-card {
  background-color: #fff6fb;
  border-radius: 15px;
  padding: 2rem 1.8rem;
  width: 30%;
  margin: 1rem;
  h2 {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }
  h4 {
    font-size: 1rem;
    line-height: 1.8rem;
  }
  p {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }
}
form {
  margin-left: 1rem;
  margin-top: 2rem;
  display: flex;
  width: 30%;
  flex-wrap: wrap;
  input, textarea {
    width: 100%;
    background-color: #edeff1;
    padding: 1rem;
    border: none;
    border-radius: 15px;
    margin-bottom: 1rem;
    color: #9da2a6;
    font-size: 0.8rem;
  }
  textarea {
    height: 15rem;
  }
}
.btn-box {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0 1rem 1rem;
  font-size: 0.8rem;
  button {
    display: flex;
    padding: 0.5rem 1.5rem;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-weight: bold;
  }
}
.cancel {
  color: #9da2a6;
  margin-right: 1rem;
  border: 1px solid #efeff0;
}
.save {
  color: #ffffff;
  background: #0075ff;
  border: none;
}

.more-vert {
  position: absolute;
  right: 1.5rem;
  display: flex;
  padding: 5px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.more-menu {
  background-color: white;
  position: absolute;
  z-index: 2;
  right: 1rem;
  display: flex;
  flex-direction: column;
  p {
    &:nth-child(1) {
      border-bottom: 1px solid grey;
    }
    display: flex;
    width: 100%;
    text-decoration: none;
    color: grey;
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    cursor: pointer;
    margin-bottom: 0;
    transition: 0.2s all ease;
    &:hover {
      background-color: #7a7a7c;
      color: white;
    }
  }
}
.bbb {
  display: flex;
  flex-direction: row-reverse;
}

.strat-but {
  border: none;
  width: 140px;
  padding: 7px;
  background-color: #0075FF;
  border-radius: 8px;
  white-space: nowrap;
  cursor: pointer;
  color: white;
}
