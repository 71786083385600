
a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.2s ease;
}

/* ---------------------------------------------------
      SIDEBAR STYLE
  ----------------------------------------------------- */
.sidebar {
  width: 250px; /* Adjusted to match closed state */
  padding: 10px;
  margin-left: 0;
  transition: width 0.5s ease;
  height: 100%;
  background: #0e0c15;
  position: fixed;
  overflow: hidden;
}

.sidebar.is-open {
  margin-left: 0;
  transition: all 0.5s ease;
  position: fixed;
  background: #0e0c15;
}
.sidebar.is-close {
  min-width: 90px;
  max-width: 90px;
  margin-left: 0px;
  position: fixed;
  background: #0e0c15;
}
.nav-items {
  white-space: nowrap;
  cursor: pointer;
  opacity: 0;
  transform: translateX(-20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  display: inline-block; /* Ensure text stays on one line */
}


.sidebar.is-open .nav-items {
  opacity: 1;
  transform: translateX(0);
}

#imggg {
  margin-top: -4px;
  
}

.nav {
  padding-left: unset !important;
}

.sidebar-header {
  padding: 28px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap !important;
}

#dimg{
  cursor: pointer;
}

.collapse.show,
.collapsing {
  background: #313b4c;
}

.nav-item.open {
  color: #e9ecef;
  background: #313b4c;
}
.nav-item .nav-link {
  color: #fff;
  padding: 0;
  display: flex;
  flex-wrap: nowrap;
  align-items: center; /* Ensure icon and text are aligned vertically */
  margin-left: 0;
}
.nav-item.active .nav-link {
  color: #7d67ff;
}

.nav-item:hover .nav-link {
  color: #7d67ff;
  transition: 0.2s ease;
}

.nav-item .svg-inline--fa {
  color: inherit;
}

li a.dropdown-toggle::after {
  display: inline-flex;
  position: relative;
  left: 60%;
  top: 10%;
}

.sidebar-header > button {
  position: relative;
  float: right;
  margin: 0.5em;
  font-size: 2rem;
  cursor: pointer;
  display: none;
}

/* ---------------------------------------------------
      CONTENT STYLE
  ----------------------------------------------------- */
.content {
  padding: 20px;
  margin-left: 0;
  height: 100vh;
}

@media only screen and (max-width: 575.98px) {
  body {
    overflow: hidden;
  }

  .content.is-open {
    /*margin-left: 100%;*/
    // display: none;
    transition: all 0.5 ease;
  }

  .sidebar.is-open {
    min-width: 100%;
    max-width: 100%;
    margin-left: 0;
    transition: all 0.5s ease, height 0s;
    height: 100vh !important;
  }

  .sidebar.is-open > .sidebar-header button {
    display: block;
  }

  li a.dropdown-toggle::after {
    display: inline-block;
    position: relative;
    left: 68%;
  }
}
