/* Base styles */
.container6 {
  height: auto;
  background: rgba(27, 30, 49, 1);
  padding: 2rem;
}

#commmm {
  text-align: left;
  align-items: start;
}

.mini {
  margin-top: 2rem;
  margin-left: 5%;
}

.divider5 {
  position: relative;
  color: #ffffff;
  margin-top: 1.5rem;
}

.divider5::before {
  content: "";
  position: absolute;
  top: 10px;
  left: -10px;
  width: 2px;
  height: 50%;
  background: linear-gradient(62.57deg, #fbb040 5.17%, #fbb040 12.63%, #fff200 59.88%);
}

.here {
  margin: 0 5%;
  color: rgba(168, 169, 179, 1);
  font-size: 1rem;
}

.follow {
  color: rgba(255, 255, 255, 1);
  margin: 1.5rem 5%;
  font-size: 1.25rem;
}

.logos {
  margin-left: 5%;
  margin-top: 1.5rem;
}

.logos img {
  margin-right: 0.75rem;
  max-width: 2rem;
  height: auto;
  cursor: pointer;
}

.pages {
  color: rgba(168, 169, 179, 1);
}

.pages > p {
  text-align: left;
  cursor: pointer;
}

.horizontal {
  border-bottom: 3px solid rgba(50, 54, 79, 1);
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 5%;
}

.footer-text {
  margin: 0;
}

.my {
  background: linear-gradient(62.57deg, #fbb040 5.17%, #fbb040 12.63%, #fff200 59.88%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.all {
  color: rgba(168, 169, 179, 1);
}

.credit img {
  margin: 0 0.5rem;
  max-width: 2rem;
  height: auto;
}

/* Media Queries */

/* Tablet and smaller devices */
@media (max-width: 768px) {
  .mini {
    margin-left: 1.5rem;
  }

  .here {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    font-size: 0.9rem;
  }

  .logos img {
    max-width: 1.5rem;
  }

  .footer-container {
    flex-direction: column;
    text-align: center;
    margin: 1rem auto;
  }

  .credit {
    margin-top: 1rem;
  }
  
  .mini3, 
  .live {
    width: 90%;
  }

  .pages {
    text-align: center;
  }
}

/* Larger screens (Desktops and above) */
@media only screen and (min-width: 1024px) {
  .mini {
    margin-top: 5rem;
    margin-left: 10%;
  }

  .here {
    margin-left: 10%;
    margin-right: 10%;
    font-size: 1rem;
  }

  .follow {
    margin-left: 10%;
    font-size: 1.5rem;
  }

  .logos {
    margin-left: 10%;
  }

  .footer-container {
    margin-left: 10%;
    margin-right: 10%;
  }
}
