#cff{
  margin: 0px 4%;
}
.term {
  margin-top: 100px;
  text-align: center;
  color: rgba(27, 30, 49, 1);
  font-family: poppins;
  font-size: 40px;
}
#startclr {
  font-weight: 500;
  background-image: linear-gradient(
    62.57deg,
    #fbb040 5.17%,
    #fbb040 12.63%,
    #fff200 59.88%
  );
  background-clip: text;
  color: transparent;
}
.circle {
  width: 227px;
  height: 227px;
  background-color: rgba(244, 244, 244, 1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  margin: 70px auto;
}

.circle-res{
  width: 263px;
  height: 263px;
  background-color: rgba(244, 244, 244, 1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  /* margin: 70px auto; */
}






.circle-res:hover {
  background-image: linear-gradient(
    62.57deg,
    #fbb040 5.17%,
    #fbb040 12.63%,
    #fff200 59.88%
  );
}

.circle:hover {
  background-image: linear-gradient(
    62.57deg,
    #fbb040 5.17%,
    #fbb040 12.63%,
    #fff200 59.88%
  );
}

.circle2 {
  width: 227px;
  height: 227px;
  background-color: rgba(244, 244, 244, 1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(27, 30, 49, 1);
  font-size: 24px;
  margin: 67px 2px;

}

.circle2:hover {
  background-image: linear-gradient(
    62.57deg,
    #fbb040 5.17%,
    #fbb040 12.63%,
    #fff200 59.88%
  );
}

.circle_small {
  width: 60px;
  height: 60px;
  background-color: rgba(255, 255, 255, 1);
  background-color: transparent;
  border: 1.42px dashed rgba(216, 216, 216, 1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(27, 30, 49, 1);
  font-size: 36px;
  margin: 151px 60px;
  cursor: pointer;
}

.circle_small-res{
  width: 72px;
  height: 70px;
  background-color: rgba(255, 255, 255, 1);
  background-color: transparent;
  border: 1.42px dashed rgba(216, 216, 216, 1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(27, 30, 49, 1);
  font-size: 36px;
  cursor: pointer;

  /* margin: 151px 67px; */
}

.small-circle-res{
  display: flex;
  justify-content: center;
}

.big-circle{
  display: flex;
  justify-content: center;
}

.step {
  rotate: -45deg;
  font-size: 17px;
  margin-top: 40px;
  font-family: poppins;
}

.circle_small2 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 50%;
  margin-top: 274px;
  rotate: 47deg;
  transform: translate(-29%, -47%);
  width: 105px;
  height: 105px;
  background: white;
  border-radius: 210px 0;
  margin-left: -34px;
  position: relative;
  /* top: 8px; */
}

.circle_small2-res{
  background-color: rgba(255, 255, 255, 1);
  border-radius: 50%;
  margin-top: 317px;
  rotate: 46deg;
  transform: translate(-29%, -47%);
  width: 105px;
  height: 105px;
  background: white;
  border-radius: 210px 0;
  margin-left: -34px;
  position: relative;
  /* top: 8px; */
}
.playbtn {
  text-align: center;
  transition: transform 0.3s ease;
  margin-bottom: 35px;
}

.playbtn:hover {
  transform: scale(1.05); /* Scale up the element */
}



.reviews{
  display: block;
}
.reviews-responsive{
  display: none;
}


@media(max-width:768px){
  .reviews{
    display: none;
  }
  .reviews-responsive{
    display:block;
  }

  .playbtn {

    margin-bottom: 15px;
    margin-top: 30px;

  }
  
}
































