#maintext {
    background: linear-gradient(62.57deg, #FBB040 5.17%, #FBB040 12.63%, #FFF200 59.88%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    text-align: center;
    margin: 2% 18%;
    font-size: 3.5rem; /* Default for larger screens */
}

.py-5 {
    background-color: rgba(27, 30, 49, 1)!important;
    background: url('../../../public/img/bg1.3.png') right no-repeat, url('../../../public/img/bg.png') left no-repeat;
    background-size: contain, contain;
    padding: 3rem 1rem;
}

#para {
    color: #ffffff;
}

#lead {
    color: rgba(168, 169, 179, 1);
    text-align: center;
    margin: 3.2% auto;
    font-size: 1.1rem;
    font-family: poppins;
    max-width: 840px; /* Ensure readability on larger screens */
}

#btn {
    margin-top: 20px;
    width: 100%; /* Full width for small screens */
    max-width: 248px;
    border-radius: 6px;
    border-image-slice: 1;
    border-image-source: linear-gradient(62.57deg, #FBB040 5.17%, #FBB040 12.63%, #FFF200 59.88%);
    background-color: black;
    color: white;
    font-family: poppins;
    font-size: 1.3rem;
    display: inline-block;
}

#btn:hover {
    border-radius: 6px;
    color: black;
    background: linear-gradient(62.57deg, #FBB040 5.17%, #FBB040 12.63%, #FFF200 59.88%);
}

/* Responsive adjustments */
@media (max-width: 1200px) {
    #maintext {
        font-size: 2.5rem;
        margin: 2% 10%;
    }
    
    #lead {
        font-size: 1rem;
        margin: 2% auto;
    }
}

@media (max-width: 768px) {
    #maintext {
        font-size: 2rem;
        margin: 3% auto;
    }
    
    #lead {
        font-size: 0.9rem;
        margin: 5% auto;
    }
    
    #btn {
        font-size: 1.2rem;
        width: 80%; /* Adjust button size for mobile */
    }
}

@media (max-width: 576px) {
    #maintext {
        font-size: 1.8rem;
        margin: 5% auto;
    }

    #lead {
        font-size: 0.85rem;
        margin: 5% auto;
    }
    
    #btn {
        font-size: 1rem;
        width: 100%;
    }
}
