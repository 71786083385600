.sign_form{
    margin-top: 2rem;
    width: 100%;
    display: block;
    margin-left: 0;
}
.fir_row{
    display: flex;
    justify-content: space-evenly;
}

.mb-2{
    text-align: left;
    width: 35%;
    font-weight: 500;
    
}

.lastt{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
}
