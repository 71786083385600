.heading1 {
    text-align: center;
    font-size: 30px;
    font-family: poppins;
    background-image: linear-gradient(62.57deg, #FBB040 5.17%, #FBB040 12.63%, #FFF200 59.88%);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}
    
#para2 {
    color: black;
    font-size: 30px;
}

#para3 {
    margin-top: 20px;
    color: rgba(244, 244, 244, 1);
    font-family: poppins;
    font-size: 17px;
}

.small-container {
    background-color: rgba(244, 244, 244, 1);
    margin: 30px 80px;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 20px;
}

.vector {
    background-color: rgba(27, 30, 49, 1);
    border-radius: 8px;
    width: 75%;
    padding: 5%;
    margin-left: 2rem !important;
}

#box {
    justify-content: flex-start;
    display: flex;
}

.pricing_rates {
    font-size: 35px;
    font-weight: 500;
}

.middle {
    height: 250px;
    background-color: rgba(122, 126, 149, 0.29);
    color: rgba(255, 255, 255, 1);
    padding-top: 20px;
    font-family: poppins;
    font-size: 17px;
    border-left: 4.36px solid #FBB040;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

#money {
    background-image: linear-gradient(62.57deg, #FBB040 5.17%, #FBB040 12.63%, #FFF200 59.88%);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}

#get {
    color: rgba(110, 110, 110, 1);
    padding-top: 15px;
    font-size: 23px;
    font-family: inter;
}

.xmcontainer {
    margin-top: 30px;
    border-radius: 8px;
    background-color: rgba(232, 232, 232, 1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 20px;
}

#xm22 {
    background-color: rgba(27, 30, 49, 1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.tick {
    font-size: 14px;
    font-weight: 600;
    color: rgba(96, 97, 101, 1);
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

#t2 {
    color: rgba(255, 255, 255, 1);
}

.para-tick {
    width: 100%;
    margin-bottom: 0rem;
}

.learn {
    margin: 13px 0px;
    background: rgba(27, 30, 49, 1);
    color: #ffffff;
    border: none;
    padding: 10px 10px;
    border-radius: 5px;
    font-size: 15px;
    font-family: poppins;
}

#l2 {
    background: rgba(244, 244, 244, 1);
    color: rgba(27, 30, 49, 1);
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .small-container {
        margin: 15px;
        padding: 15px;
    }

    .row {
        display: block;
        text-align: center;
    }

    .vector {
        width: 100%;
        margin-left: 0px !important;
        padding: 10px;
    }

    .col-xl-5,
    .col-xl-6 {
        width: 100%;
        margin-bottom: 20px;
    }

    .start, #get {
        padding-left: 15px;
        text-align: center;
        font-size: 20px;
    }

    .pricing_rates {
        font-size: 25px;
    }

    .middle {
        height: auto;
        padding: 15px;
    }

    .ellipse {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .tick {
        justify-content: flex-start;
    }

    .learn {
        width: 100%;
    }

    #l2 {
        width: 100%;
    }

    .divider2, .divider3 {
        margin: 0 10%;
    }
}
