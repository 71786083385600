.react-calendar {
  width: 1230px;
  /* height: 669px; */
  /* max-width: 100%; */
  background: white;
  border: 1.5px solid#EFEFF0;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 3.125em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  justify-content: flex-start;
  height: 44px;
  margin-bottom: 0.5em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  color: #1B1B1B;
  font: inherit;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  height: 70px;
  border: 1.5px solid#EFEFF0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;

  abbr[title] {
    text-decoration: none;
  }
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font: inherit;
  font-size: 1.75em;
  font-weight: bold;
}


.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  /* max-width: 100%;*/
  padding: 30px 16.6667px;
  background: none;
  text-align: center;
  font: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  border-radius: 10px;
  min-width: 50px;

  /* height:46px; */
  abbr {
    background-color: #A3A3A3;
    padding: 1rem 1.25rem;
    border-radius: 10px;
    color: white;
  }
}

.profit {
  abbr {
    background-color: #26E02E;
    padding: 1rem 1.25rem;
    border-radius: 10px;
    color: black;
  }
}

.loss {
  abbr {
    background-color: #F82727;
    padding: 1rem 1.25rem;
    border-radius: 10px;
  }
}

.react-calendar__month-view__days__day--neighboringMonth {
  abbr {
    background-color: white;
    padding: 1rem 1.25rem;
    border-radius: 10px;
    color: black;
  }
}

.data-label {
  font: inherit;
  font-size: 17px;
  font-weight: 500;
  line-height: 21.79px;
  color: #1B1B1B;
  margin-right: 50px;
}

.label-color {
  width: 25px;
  height: 25px;
  margin-right: 12px;
  border-radius: 5px;
}

.profit-label-color{
  background-color: #26E02E;
}

.loss-label-color{
  background-color: #F82727;
}

.noTrade-label-color{
  background-color: #A3A3A3;
}

.close_button{
  background-color: #0075ff;
  border: none;
  color: white;
  border-radius: 5px;
  padding: 3px;
}