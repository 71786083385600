#fom {
  margin: auto;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

#op {
  border: 0.5px solid rgb(219, 219, 219);
  border-radius: 5px;
  background-color: white;
  padding: 7px;

  &:hover {
    border: 0.5px solid #007bff;
    transition: 1s ease;
  }
}

.reb{
    color: white;
    margin: auto;
    padding: 6px;
    background-color: #9574ff;
    border-radius: 5px;
    width: 35%;

    &:hover{
        background-color: #7f58ff;
        transition: all 0.5s ease;
    }
}
