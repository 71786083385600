.compare {
  text-align: center;
  color: rgba(36, 36, 36, 1);
  font-family: Poppins, sans-serif;
  font-size: 40px;
  padding-top: 2%;
}

#main_table {
  width: 90%;
  margin: auto;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
}

#plan {
  background-image: linear-gradient(90deg, #fbb040 0%, #fbb040 43.83%, #fff200 99.33%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

#choose {
  padding-top: 2%;
  font-family: Poppins, sans-serif;
  vertical-align: baseline;
  padding-left: 2%;
  font-size: 24px;
  background-color: rgba(244, 244, 244, 1);
}

.content-pricing {
  text-align: center;
}

.text_basic {
  margin-top: 7%;
  font-family: Poppins, sans-serif;
  color: rgba(27, 30, 49, 1);
  font-size: 26px;
}

.table_text {
  text-align: center;
  padding: 8px;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden;     /* Hide overflow if text is too long */
  text-overflow: ellipsis; /* Show '...' for overflowed text */
  vertical-align: middle; /* Align ticks vertically with the text */
  font-size: 14px; /* Adjust font size */
}

.table_text i {
  color: rgb(0, 157, 0);
}

.icon-center {
  text-align: center;
  vertical-align: middle; /* Ensure ticks are vertically centered */
}

.toggle-container {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.toggle-button {
  display: flex;
  position: relative;
  border: 1px solid #ccc;
  border-radius: 25px;
  overflow: hidden;
  width: 200px; /* Adjust for larger screens */
  height: 40px; /* Ensure enough height for the text */
  font-size: 14px;
}

.toggle-option {
  flex: 1;
  padding: 10px 0; /* Adjust padding to avoid text cutoff */
  text-align: center;
  cursor: pointer;
  z-index: 1;
  font-size: 14px;
  font-weight: 400;
}

.toggle-background {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  height: 100%; /* Ensure background covers the full height */
  background-color: rgba(20, 50, 93, 1);
  border-radius: 25px;
  transition: transform 0.5s ease;
  z-index: 0;
}

.toggle-option.selected {
  color: white;
}
/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  .compare {
    font-size: 28px;
  }
  .table_text {
    font-size: 12px; /* Smaller font size for tablets */
  }

  #main_table {
    width: 100%;
    margin: auto;
  }

  #choose {
    font-size: 20px;
    padding-left: 5%;
  }

  .text_basic {
    font-size: 22px;
  }
  .content-pricing {
    font-size: 18px;
  }

  .learn {
    font-size: 14px;
    padding: 8px 10px;
    width: 80%;
  }

  .toggle-button {
    width: 150px; /* Slightly smaller width for tablets */
    height: 35px; /* Reduced height for smaller screens */
    font-size: 12px;
  }

  .toggle-option {
    font-size: 12px;
    padding: 8px 0;
  }
}

@media (max-width: 480px) {
  .compare {
    font-size: 22px;
    padding-top: 5%;
  }

  #choose {
    font-size: 16px;
  }

  .text_basic {
    font-size: 18px;
  }

  .table_text {
    font-size: 11px; /* Even smaller font size for mobile */
    white-space: normal; /* Allow wrapping on very small screens */
  }

  .toggle-button {
    width: 120px; /* Reduced width for smaller mobile devices */
    height: 30px;  /* Ensure the text fits well */
    font-size: 11px;
  }

  .toggle-option {
    font-size: 11px;
    padding: 6px 0;
  }

  .toggle-background {
    height: 100%; /* Ensure it covers the full height */
  }

  .content-pricing {
    font-size: 16px;
  }

  .learn {
    font-size: 12px;
    padding: 5px 8px;
    width: 90%;
  }

  #main_table {
    width: 100%;
  }
}
