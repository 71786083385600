.container5 {
  padding: 10px; /* Reduced padding for mobile */
}

.center-text {
  text-align: center;
  padding-top: 20px; /* Adjust padding for mobile */
  color: rgba(110, 110, 110, 1);
}

.find {
  text-align: center;
  color: rgba(27, 30, 49, 1);
  font-size: 28px; /* Reduced font size for mobile */
  font-family: "Poppins", sans-serif;
}

.faq {
  width: 100%;
  max-width: 100%; /* Make sure it takes full width on mobile */
  margin: 0 auto;
}

.ques, .ques2 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 15px; /* Adjust margin for better spacing on mobile */
  border-radius: 5px;
  background-color: rgba(244, 244, 244, 1);
}

.question-content {
  display: flex;
  align-items: center;
  padding: 10px 15px; /* Reduce padding */
  width: 100%;
  flex-direction: column; /* Stack items vertically on mobile */
}

.question-content img {
  margin-bottom: 10px; /* Space between image and text */
  max-width: 50px; /* Scale down the image on mobile */
  height: auto;
}

.question-text {
  flex: 1;
  text-align: center; /* Center align the text for better appearance on mobile */
}

#how, #how2 {
  font-family: "Poppins", sans-serif;
  font-size: 16px; /* Smaller font for mobile */
  color: rgba(27, 30, 49, 1);
  font-weight: 500;
}

#mole, #mole2 {
  margin-top: 5px;
  font-family: "Poppins", sans-serif;
  font-size: 13px; /* Adjusted font size */
  color: rgba(110, 110, 110, 1);
}

#ans {
  background: linear-gradient(62.57deg, #fbb040 5.17%, #fbb040 12.63%, #fff200 59.88%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

#plus, #minus {
  margin-top: 10px; /* Adjust for better alignment */
  cursor: pointer;
}

/* Media Queries for responsive design */

@media only screen and (min-width: 768px) {
  .find {
      font-size: 36px;
  }

  .ques, .ques2 {
    flex-direction: row; /* Revert to row on tablets and larger screens */
  }

  .question-content {
    flex-direction: row;
    padding: 12px 20px;
  }
}

@media only screen and (min-width: 1024px) {
  .center-text {
      padding-top: 50px;
  }

  .faq {
      max-width: 900px;
  }

  .question-content {
      padding: 12px 30px;
  }
}
