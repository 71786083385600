.test {
  height: max-content;
  margin-bottom: 40px;
}

.boxes {
  display: flex;
  justify-content: space-evenly;
}

.headi {
  width: fit-content;
  text-align: left;
  font-size: 40px;
  font-family: Poppins;
  color: transparent;
  background-image: linear-gradient(90deg, #fbb040 0%, #fbb040 43.83%, #fff200 99.33%);
  background-clip: text;
}

.info1 {
  width: 55%;
  border-radius: 8px;
  height: max-content;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.info1-headi {
  text-align: left;
  margin: 10px 20px;
  font-size: 21px;
}

.info2 {
  color: white;
  width: 25%;
  border-radius: 8px;
  height: max-content;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: rgba(27, 30, 49, 1);
}

.info2-headi {
    text-decoration: underline;
  text-align: left;
  margin: 10px 20px;
  font-size: 21px;
}

.inputs1 {
  display: flex;
  margin: 25px 0px;
}

.cou {
  display: flex;
  justify-content: center;
  margin: 20px 0px;
}

.apply_button {
  width: 20%;
  background-color: #fbb040;
  align-content: center;
  border-radius: 4px;
  font-size: 17px;
}

.apply_button:hover {
  cursor: pointer;
}

.names {
  width: 50%;
}
.names2 {
  width: 100%;
}

.inp {
  width: 80%;
  padding: 4px 10px;
  border: rgb(180, 180, 180) 1px solid;
  border-radius: 4px;
}
.inp2 {
  width: 90%;
  padding: 5px 10px;
  border: rgb(180, 180, 180) 1px solid;
  height: 100px;
  border-radius: 4px;
}
.inp3 {
  width: 90%;
  padding: 4px 10px;
  border: rgb(180, 180, 180) 1px solid;
  border-radius: 4px;
}

.line {
  border: 2px solid #ffffff;
  margin: 20px 25px;
}

.amounts {
  display: flex;
  justify-content: center;
  padding: 3px 5px 10px 5px;
}

.amount_type {
  width: 39%;
  text-align: left;
  font-size: 15px;
  align-content: center;
}

.amount_num {
  width: 18%;
  text-align: right;
  font-size: 15px;
  margin-top: 1.5px;
  align-content: center;
}

.tc{
    display: flex;
    justify-content: center;
    padding-bottom: 8px;

    label{
        font-size: 13px;
        padding-bottom: 2px;
        padding-left: 5px;
    }
}

#tandc{
  width: auto;
    margin-bottom: 0px;
}

#total {
  font-size: large;
  font-weight: 600;
}

.pay_button {
  padding: 7px;
  color: white;
  margin-bottom: 15px;
  margin-top: 10px;
  width: 50%;
  background-color: #fbb040;
  border-radius: 5px;
  font-weight: 500;
}

.pay_button:hover {
  transition: 0.5s ease;
  background-color: #ed9612;
  color: black;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  width: 400px;
}

.popup-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
}

.popup-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #9574ff;
  color: white;
  font-size: 16px;
}

.popup-button:hover {
  background-color: #764dff;
  transition: all 0.5s ease;
}

.subb{
    font-size: 0.8rem;
    padding-bottom: 15px;
    padding-top: 5px;
}

#finall{
  font-weight: 600;
}
