.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 60px;

    button {
        margin: 5px;
        padding: 8px 12px;
        cursor: pointer;
        border: none;
        border-radius: 5px;
        background-color: #e0e0e0;
    }

    button.active {
        background-color: #007bff;
        color: #fff;
    }
}

.imgs{
    height: 15px;
    width: 15px;
    margin: "5px";
    cursor: pointer;
}